import React from "react";

import Layout from "../components/Layout";
import Title from "../components/Title";
import ContactForm from "../components/ContactForm";

const ContactPage = ({ path }) => {
  return (
    <Layout currentPath={path} title="Contact Us">
      <Title label="Contact Us" />

      <section className="px-4 py-8 container mx-auto">
        <p className="my-4 text-lg text-gray-700 dark:text-gray-300">
          Want to work with us? Leave your contact here and we will get back to
          you.
        </p>
        <ContactForm />
      </section>
    </Layout>
  );
};

export default ContactPage;
